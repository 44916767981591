import React from 'react'
import { graphql } from "gatsby"
import { Flex, Box } from 'reflexbox'
import { Helmet } from 'react-helmet'

import Container from '../components/Container'
import Header from '../components/Header'
import H1 from '../components/H1'
import H2 from '../components/H2'
import P from '../components/P'
import Footer from '../components/Footer'
import Layout from '../components/layout'

const Index = ({ data, location }) => (
  <Layout>
    <Helmet>
      <title>Gemeinderat - CSU Ortsverband Kastl</title>
    </Helmet>
    <Header pathname={location.pathname}/>
      <Container>
        <H1>CSU Gemeinderatsmitglieder</H1>
        <Flex flexWrap='wrap' marginX={-3}>
          {
            data.graphcms.councilors.map(({ id, name, roles, description, photo: { url } }) => (
              <Box
                key={id}
                width={[ 1, 1 / 2, 1 / 3, 1 / 4 ]}
                px={3}
                pb={4}
              >
                {url && <img css={{ maxWidth: '100%', width: '100%', height: 'auto', marginBottom: '12px' }} src={url} />}
                <H2>{name}</H2>
                {roles && <P><strong>{roles}</strong></P>}
                {description && <P>{description}</P>}
              </Box>
            ))
          }
      </Flex>
    </Container>
    <Footer />
  </Layout>
)

export default Index

export const pageQuery = graphql`
  query {
    graphcms {
      councilors(orderBy: position_ASC) {
        id
        name
        roles
        description
        photo {
          url(transformation: { image: { resize: { width: 768 } } })
        }
      }
    }
  }
`